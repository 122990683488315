<template>
  <div class="productWrapper">
      <van-card
        v-for="(item, index) in products"
        :key="item.id"
        :price="formatePrice(item.retailPrice)"
        :desc="item.desc"
        :title="item.name"
        :thumb="
          item.img || 'https://fastly.jsdelivr.net/npm/@vant/assets/ipad.jpeg'
        "
      >
        <!-- <template #tags>
        <van-tag plain type="primary">{{ item.unit }}</van-tag>
      </template> -->
        <template #footer>
          <van-button size="normal" @click="modifyProduct(item)" class="fs14"
            >编辑</van-button
          >
        </template>
      </van-card>
  </div>
</template>

<script>
import { Field, Button } from "vant";
export default {
  name: "ProductItem",
  components: {
    vanButton: Button,
  },
  props: {
    products: Array,
  },
  data() {
    return {
      loading:false,
      productsBySort: [],
      showPicker: false,
      formData: {
        updatePrice: 0,
      },
      name: "",
    };
  },
  created() {},
  methods: {
    formatePrice(price) {
      console.log(price)
      return String(price).includes('.')?price:price+'.00'
    },
    modifyProduct(row) {
      console.log(row);
      this.$router.push("/product/modify/" + row.id);
    },
  },
  beforeDestroy() {},
};
</script>

<style scoped>
::v-deep .van-card__title {
  font-size: 14px;
}
</style>
