<template>
  <div id="category">
    <!--头部-->
    <van-search v-model="value" placeholder="请输入商品名称" @search="search" />
    <div class="listWrapper" v-if="!showLoading">
      <!--左边-->
      <div class="leftWrapper">
        <ul class="wrapper">
          <li
            class="categoryItem"
            v-for="(cate, index) in categories"
            :class="{ selected: currentIndex === index }"
            @click="clickLeft(index, cate.id)"
            :key="index"
            ref="menuList"
            
          >
            <span class="textWrapper">{{ cate.name }}</span>
          </li>
        </ul>
      </div>
      <!--右边-->
      <ContentView :categoriesDetail="categoriesDetail" />
      <!-- <comment-popup  :popup.sync="showPostDialog"  /> -->
    </div>
  </div>
</template>

<script>
// 1. 引入组件
import Header from "./components/header/Header";
import ContentView from "./components/contentView/ContentView";
// 2. 引入滚动组件
import BScroll from "better-scroll";

// 3. 引入接口
import { getCategories, getCategoriesDetail } from "./../../service/api/index";
// 4. 引入pubsub
import PubSub from "pubsub-js";
// 5. 引入vuex
import { mapMutations, mapState } from "vuex";
// 6. 引入toast
import { Toast } from "vant";
import { addGoodsToCart } from "./../../service/api/index";
import { reqWeightCategory, reqCategoryItems } from "../../service/api/product";

export default {
  name: "Category",
  categories: Array,
  code: null,
  data() {
    return {
      // 是否显示正在加载
      showLoading: true,
      // 左侧列表数组
      categories: [],
      categoriesDetail: [],

      currentIndex: 0,
      query: {
        name: "",
        pageIndex: 1,
        pageSize: 100,
        available: true,
        categoryId: "",
      },
      value: "",
      loading: false, // 滚动加载中
      finished: false, // 滚动加载完成
      isLoading: false, // 下拉强制刷新
    };
  },
  components: {
    Header,
    ContentView,
  },
  computed: {
    ...mapState(["shopCart", "userInfo"]),
  },
  methods: {
    ...mapMutations(["ADD_GOODS"]),
    async search(flag) {
      console.log(flag)
      if(flag!==true) {
      this.query.categoryId = ''}
      this.query.name = this.value;
      let rightRes = await reqCategoryItems(this.query);

      if (rightRes.code == 200) {
        this.categoriesDetail = [];
        rightRes.data.datas.forEach((item) => {
          if (this.categoriesDetail.length == 0) {
            this.categoriesDetail.push({
              id: item.categoryId,
              name: item.categoryName,
              products: [item],
            });
          } else {
            let newcategoryId = this.categoriesDetail.find(
              (i) => i.id == item.categoryId
            );
            if (!newcategoryId) {
              this.categoriesDetail.push({
                id: item.categoryId,
                name: item.categoryName,
                products: [item],
              });
            } else {
              newcategoryId.products.push(item);
            }
          }
        });
      }
    },

    async getRes() {
      reqWeightCategory().then(
        //1. 获取左边数据
        //  getCategories().then(
        async (res) => {
          if (res.code == 200) {
            this.categories = res.data;
            console.log(this.query, this.categories[0].id);
            this.clickLeft(0, this.categories[0].id);
            // 3. 隐藏正在加载
            this.showLoading = false;
            // 4. 初始化滚动框架，一般在下一个周期执行
            this.$nextTick(() => {
              this.leftScroll = new BScroll(".leftWrapper", {
                probeType: 3,
                click: true,
                scrollY: true,
                tag: true,
                mouseWheel: true,
              });
            });
          }
        }
      );
    },
    // 2. 处理左侧点击
    async clickLeft(index, id) {
      console.log(index);
      // 2.1 改变索引
      this.currentIndex = index;
      // 2.2 获取点击的li标签
      if (this.$refs.menuList) {
        let li = this.$refs.menuList && this.$refs.menuList[index];
        // 2.3 滚动到对应的元素上
        this.leftScroll.scrollToElement(li, 300);
      }

      this.query.categoryId = id;
      // 2. 获取右边数据
      this.search(true);
    },
  },
  mounted() {
    this.getRes();
  },
  beforeDestroy() {},
  created() {},
};
</script>

<style lang="less" scoped>
#category {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
  margin-top:3rem
}

.listWrapper {
  display: flex;
  position: absolute;
  top: 6rem;
  bottom: 3rem;
  width: 100%;
  overflow: hidden;
}

.leftWrapper {
  background-color: #f4f4f4;
  width: 5.3125rem;
  flex: 0 0 5.3125rem;
}

.categoryItem {
  padding: 0.75rem 0;
  border-bottom: solid 1px #e8e9e8;
  position: relative;
}

.categoryItem .textWrapper {
  line-height: 1.25rem;
  border-left: solid 0.1875rem transparent;
  padding: 0.3125rem 0.6875rem;
  font-size: 0.675rem;
  color: #666666;
}

.categoryItem.selected {
  background: #fff;
}

.categoryItem.selected .textWrapper {
  border-left-color: #0960bd;
  font-weight: bold;
  font-size: 0.675rem;
  color: #333333;
}

@media (min-width: 960px) {
  .wrapper {
    border-right: 1px solid #e8e9e8;
  }

  .wrapper .categoryItem {
    background: #fff;
  }
}
</style>
